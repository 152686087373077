import React, {RefObject} from "react";
import {addCssClassToRef} from "../shared/functions/add-css-class-to-ref.function";
import {removeCssClassFromRef} from "../shared/functions/remove-css-class-from-ref.function";
import logoBlack from "../shared/images/logo.svg";
import logoGreen from "../shared/images/logo.png";
import {Color} from "./Logo.component";
import {handleColorFunction} from "../shared/functions/handle-color.function";

export class HamburgerComponent extends React.Component<{ onClick: (e: any) => void }, any> {
    private _btnRef!: RefObject<HTMLButtonElement>;
    private _isActive = false;
    private readonly isActiveCssClass = "is-active";
    private readonly _colorGreen: Color = "bg-green";
    private readonly _colorBlack: Color = "bg-black";
    private readonly _colorGray: Color = "bg-light-gray";
    private _prevColor: Color = "bg-light-gray";

    private readonly _bgColorToHamburgerColor: { [key in Color]: string } = {
        "bg-green": "bg-light-gray",
        "bg-black": "bg-light-gray",
        "bg-light-gray": "bg-black"
    };

    state = {
        activeColor: this._colorGray
    }

    constructor(props: any) {
        super(props);
        this._initReferences();
        this._handleScroll = this._handleScroll.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }

    resetHamburger() {
        removeCssClassFromRef(this._btnRef, this.isActiveCssClass);
        this._isActive = false;
    }

    private _handleScroll() {
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        const color = handleColorFunction();
        if(this.state.activeColor !== color) this.setColor(color);
    }

    public setColor(color: Color) {
        this._prevColor = this.state.activeColor;
        this.setState({activeColor: color})
    }

    public resetColorToLast() {
        this.setColor(this._prevColor);
    }

    private _toggleHamburger(e: any) {
        if (!this._isActive) {
            addCssClassToRef(this._btnRef, this.isActiveCssClass);
            this.setColor('bg-green');
        } else {
            removeCssClassFromRef(this._btnRef, this.isActiveCssClass);
            this.resetColorToLast();
        }
        this._isActive = !this._isActive;
        this.props.onClick(e);
    }

    private _initReferences() {
        this._btnRef = React.createRef();
        this._toggleHamburger = this._toggleHamburger.bind(this);
    }

    render() {
        return (
            <button type="button" className="hamburger hamburger--spring" onClick={this._toggleHamburger} ref={this._btnRef}>
              <span className="hamburger-box">
                <span className={['hamburger-inner', this._bgColorToHamburgerColor[this.state.activeColor]].join(' ')} />
              </span>
            </button>
        )
    }
}
