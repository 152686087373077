import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import {ImprintPage} from "./pages/imprint.page";
import NavComponent from "./components/Nav.component";
import {DataService} from "./shared/data.service";

(async() => {
    await DataService.instance.init();
    ReactDOM.render(
        <BrowserRouter>
            <NavComponent></NavComponent>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/impressum" element={<ImprintPage />}/>
            </Routes>
        </BrowserRouter>,
        document.getElementById('root')
    );
    reportWebVitals();
})()
