export class ScrollService {
    private static _instance: ScrollService;

    private constructor() {
    }

    static get instance() {
        if (!this._instance) {
            this._instance = new ScrollService();
        }
        return this._instance;
    }

    allowScroll() {
        const html = document.getElementsByTagName('html')
        if (html && html[0]) html[0].classList.add('fullscreen');
    }

    denyScroll() {
        const html = document.getElementsByTagName('html')
        if (html && html[0]) html[0].classList.remove('fullscreen');
    }
}
