import React from "react";
import {LINK_TO_ABOUT_ME_SECTION, LINK_TO_CONTACT_SECTION} from "../shared/router.constants";
import {Link} from "react-router-dom";
import {scrollIntoView} from "../shared/functions/scroll-to.function";
import {DataService} from "../shared/data.service";

export class AboutMeSection extends React.Component {
    private get _data() {
        return DataService.instance.aboutMe
    }
    render() {
        return <section id={LINK_TO_ABOUT_ME_SECTION}
                        className="d-flex justify-content-center align-items-center bg-green color-black">
            <div className="container">
                <div className="row my-10">
                    <div className="col-12 mb-5">
                        <h1 className="display-1">{this._data.header}  &#8212; </h1>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-8 offset-lg-4">
                        <p> {this._data.text}<a onClick={() => scrollIntoView(LINK_TO_CONTACT_SECTION)}>{this._data.link}</a></p>
                    </div>
                    <div className="col-6 col-lg-3 col-md-4 arrow-left-down-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
                            <rect fill="none"/>
                            <path d="M15,19v-2H8.41L20,5.41L18.59,4L7,15.59V9H5v10H15z"/>
                        </svg>
                    </div>
                </div>
                <div className="row my-5">
                    <div className="col-lg-4 col-md-12 py-2 max-height-100 order-0">
                        <h2><span className="color-yellow">&#8212; </span> {this._data.pulletPoints[0].header}</h2>
                    </div>
                    <div className="col-lg-4 col-md-12 py-2 max-height-100  order-2 order-lg-1">
                        <h2><span className="color-yellow">&#8212;</span> {this._data.pulletPoints[1].header}</h2>
                    </div>
                    <div className="col-lg-4 col-md-12 py-2 max-height-100  order-4 order-lg-2">
                        <h2><span className="color-yellow">&#8212;</span> {this._data.pulletPoints[2].header}</h2>
                    </div>
                    <div className="col-lg-4 col-md-12 py-2 order-1 order-lg-3 pb-8">
                        <p className="border-top-lg border-black py-2">{this._data.pulletPoints[0].text}</p>
                    </div>
                    <div className="col-lg-4 col-md-12 py-2 order-3 order-lg-4 pb-8">
                        <p className="border-top-lg border-black py-2">{this._data.pulletPoints[1].text}</p>
                    </div>
                    <div className="col-lg-4 col-md-12 py-2 order-5 pb-8">
                        <p className="border-top-lg border-black py-2">{this._data.pulletPoints[2].text}</p>
                    </div>
                </div>
            </div>
        </section>
    }
}
