import React from "react";
import {Service} from "../sections/service.section";
import {ScrollService} from "../shared/scroll.service";

export interface Image {
    alt: string
    src: string
}

const colorToCssClass: { [k: string]: string } = {
    yellow: "bg-yellow",
    gray: "bg-dark-gray",
    green: "bg-green",
    white: "bg-light-gray",
};

export class Slider extends React.Component<{ service?: Service | any, images?: Image[] }, { isInFullscreen: boolean }> {
    private readonly _id = "carouselControls-" + Date.now() + "-" + Math.floor(Math.random() * 1000);

    state = {
        isInFullscreen: false
    }

    constructor(props: any) {
        super(props);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this._handleKeyDown.bind(this));
    }

    private _handleKeyDown(e: any) {
        switch (e.key) {
            case "Escape":
                this._toggleFullscreen(false);
                break;
            case "ArrowRight":
                this._next();
                break;
            case "ArrowLeft":
                this._prev();
                break;
        }
    }

    private _next() {
        const el = document.getElementById(this._id);
        // @ts-ignore
        if (bootstrap && bootstrap.Carousel) {
            // @ts-ignore
            new bootstrap.Carousel(el).next();
        }
    }

    private _prev() {
        const el = document.getElementById(this._id);
        // @ts-ignore
        if (bootstrap && bootstrap.Carousel) {
            // @ts-ignore
            new bootstrap.Carousel(el).prev();
        }
    }

    private _toggleFullscreen(goIntoFullscreen?: boolean) {
        const el = document.getElementById(this._id);

        if (el) {
            goIntoFullscreen = goIntoFullscreen === undefined ? el.classList.contains("fullscreen") : !goIntoFullscreen;
            if (goIntoFullscreen) {
                ScrollService.instance.denyScroll();
                el.classList.remove("fullscreen")
                window.removeEventListener("keydown", this._handleKeyDown.bind(this));
                window.removeEventListener("touchstart", this._handleTouchStart.bind(this));
                window.removeEventListener("touchmove", this._handleTouchMove.bind(this));
                window.removeEventListener("touchend", this._handleTouchEnd.bind(this));
            } else {
                ScrollService.instance.allowScroll();
                el.classList.add("fullscreen")
                window.addEventListener('keydown', this._handleKeyDown.bind(this));
                window.addEventListener("touchstart", this._handleTouchStart.bind(this));
                window.addEventListener("touchmove", this._handleTouchMove.bind(this));
                window.addEventListener("touchend", this._handleTouchEnd.bind(this));
            }
            this.setState({isInFullscreen: !goIntoFullscreen})
        }
    }

    touch = {
        clientYStart: 0,
        clientYEnd: 0,
    }

    private _handleTouchStart(e: TouchEvent | any) {
        const {clientY} = e?.touches[0] || e?.originalEvent?.touches[0];
        this.touch.clientYStart = clientY;
    }

    private _handleTouchEnd() {
        const swipeDown = (this.touch.clientYStart - this.touch.clientYEnd) > 80;
        if (swipeDown) this._toggleFullscreen(false);
        this.touch.clientYStart = 0;
    }

    private _handleTouchMove(e: TouchEvent | any) {
        const {clientY} = e?.touches[0] || e?.originalEvent?.touches[0];
        this.touch.clientYEnd = clientY;
    }

    private _addFullscreen(el: any) {
        el.classList.add("fullscreen");
    }

    private _removeFullscreen(el: any) {
        el.classList.add("fullscreen");
    }

    private get _images() {
        if (this.props?.service && Array.isArray(this.props.service?.images)) {
            return this.props.service.images
        } else if (Array.isArray(this.props?.images)) {
            return this.props.images;
        }
        return [];
    }

    private get _hashId() {
        return "#" + this._id;
    }

    render() {
        return <div>
            <div id={this._id} className="carousel slide bg-black"
                 data-bs-ride="carousel">
                {
                    this._images.length > 1
                        ? <div className="carousel-indicators">
                            {
                                this._images.map((v: any, i: number) =>
                                    <button type="button"
                                            key={i}
                                            data-bs-target={this._hashId}
                                            data-bs-slide-to={i}
                                            className={i === 0 ? "active" : ""}
                                            aria-current="true"></button>
                                )
                            }
                        </div>
                        : ""
                }
                <div className="carousel-inner">
                    {
                        this._images.map((v: any, i: number) => <div key={v.src}
                                                                     className={i === 0 ? "carousel-item active" : "carousel-item"}>
                                <img src={v.src} className="d-block w-100" alt={v.alt}/>
                            </div>
                        )
                    }
                </div>
                {
                    this._images.length > 1
                        ? <div>
                            <button className="carousel-control-prev" type="button"
                                    data-bs-target={this._hashId} data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button"
                                    data-bs-target={this._hashId} data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        : ""
                }
                <div className="material btn-fullscreen" onClick={() => this._toggleFullscreen()}>
                    {
                        this.state.isInFullscreen
                            ? <svg viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M14,14H19V16H16V19H14V14M5,14H10V19H8V16H5V14M8,5H10V10H5V8H8V5M19,8V10H14V5H16V8H19Z"/>
                            </svg>
                            : <svg viewBox="0 0 24 24">
                                <path fill="currentColor"
                                      d="M5,5H10V7H7V10H5V5M14,5H19V10H17V7H14V5M17,14H19V19H14V17H17V14M10,17V19H5V14H7V17H10Z"/>
                            </svg>
                    }
                </div>
            </div>
            <div className="text-align-left py-3">
                <div
                    className="d-flex justify-content-between align-items-center">
                    <h4>{this.props?.service?.header ?? ''}</h4>
                    <div className="circle-wrapper ms-2">
                        <div
                            className={['circle', colorToCssClass[this.props.service.color]].join(' ')}/>
                    </div>
                </div>
            </div>
        </div>
    }
}
