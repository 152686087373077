import React from "react";
import {LINK_TO_SERVICE_SECTION} from "../shared/router.constants";
import {ListComponent} from "../components/List.component";
import {Image, Slider} from "../components/Slider.component";
import {scrollIntoView} from "../shared/functions/scroll-to.function";
import {DataService} from "../shared/data.service";
export type Color = "green" | "gray" | "yellow" | "white";

const colorToCssClass: { [k: string]: string } = {
    yellow: "bg-yellow",
    gray: "bg-dark-gray",
    green: "bg-green",
    white: "bg-light-gray",
};

export interface Service {
    id: string
    header: string
    texts: string[]
    color: Color
    images: Image[]
}

export class ServiceSection extends React.Component {
    private get _data() {
        return DataService.instance.services
    }
    render() {
        return (
            <section id={LINK_TO_SERVICE_SECTION} className="bg-black">

                <div className="service-container-first container d-flex justify-content-start align-items-start">
                    <div className="row">
                        <div className="col-12 my-10 color-light-gray">
                            <h1 className="display-1">{this._data.header} &#8212; </h1>
                        </div>
                        <div className="col-12">
                            <ul className="service-list list-group list-normal">
                                {
                                    this._data.items.map((s, index) => (
                                            <li className="list-group-item d-flex justify-content-start align-items-center my-2 my-lg-4"
                                                key={index}>
                                                <div className="circle-wrapper me-2 me-md-4">
                                                    <div className={['circle', colorToCssClass[s.color]].join(' ')}/>
                                                </div>
                                                <div className="color-light-gray  ">
                                                    <h2 style={{textDecoration: "underline", cursor: 'pointer'}} className="own-display-2"  onClick={() => scrollIntoView(s.id)}>{s.header}</h2>
                                                </div>
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="service-container-second container max-width-100">
                    <div className="row bg-black">
                        {
                            this._data.items.map((service, index) => (
                                <div id={service.id} className="col-sm-12 col-md-6 service-slider-container"
                                     key={index}>
                                    <div
                                        className={['overlay d-flex align-items-center justify-content-center', colorToCssClass[service.color]].join(" ")}>
                                        <h1>{service.header}</h1>
                                    </div>
                                    <div
                                        className="custom-slider d-flex align-items-start justify-content-center bg-light-gray color-black py-3 px-3 p-xl-5">
                                        <div>
                                            <Slider key={index} service={service}/>
                                            <div>
                                                <ul className="list-group list-group-flush border-top-sm">
                                                    <li className="list-group-item">
                                                        <p>{service.texts[0] || ''}</p>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <p>{service.texts[1] || ''}</p>
                                                    </li>
                                                    <li className="list-group-item"><p>{service.texts[2] || ''}</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        )
    }
}
