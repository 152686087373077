import {Color} from "../../components/Logo.component";

export function handleColorFunction(): Color {
    let color: Color = "bg-light-gray";
    Array.from(document.getElementsByTagName("section")).forEach((el, index) => {
        let {top, bottom} = el.getBoundingClientRect();
        if (top < 0 && bottom > 0) {
            const green: Color = "bg-green";
            const black: Color = "bg-black";
            const gray: Color = "bg-light-gray";

            if (el.classList.contains(green)) {
                color = green;
            } else if (el.classList.contains(black)) {
                if(index == 2 && el.getElementsByTagName('div')[0].getBoundingClientRect().bottom > 0) {
                    color = black;
                } else {
                    color = gray;
                }
            } else {
                color = gray;
            }
        }
    })
    return color;
}
