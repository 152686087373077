import React from "react";
import {LINK_TO_CONTACT_SECTION} from "../shared/router.constants";
import {DataService} from "../shared/data.service";

export class ContactSection extends React.Component {
    private get _contanctData() {
        return DataService.instance.contact
    }

    private get _data() {
        return DataService.instance.all
    }

    render() {
        return <section id={LINK_TO_CONTACT_SECTION}
                        className="d-flex justify-content-center align-items-center bg-green color-black  min-vh-100">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1 className="display-1">{this._contanctData.header} &#8212;</h1>
                    </div>
                    <div className="col-12">
                        <div className="row align-items-center">
                            <div className="col-3">
                                <svg className="arrow-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                     fill="#000000">
                                    <rect fill="none" height="24" width="24"/>
                                    <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/>
                                </svg>
                            </div>
                            <div className="col-9">
                                <div className="d-flex justify-content-center align-items-center">
                                    <h2 className="own-display-2">{this._contanctData.subHeader}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 mt-5">
                        <ul className="list-group list-group-flush list-lines border-black">
                            <li className="list-group-item d-flex justify-content-between align-items-center font-white">
                                <p>{this._data.email.label}</p>
                                <a href={"mailTo:" + this._data.email.value}>info@malerlw.at</a>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center font-white">
                                <p>{this._data.tel.label}</p>
                                <a href={"tel:" + this._data.tel.value.replaceAll(" ", "")}>{this._data.tel.value}</a>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center font-white">
                                <p>{this._data.address.label}</p>
                                <a target="_blank"
                                   href={"https://maps.google.com/?q=" + this._data.address.value}>{this._data.address.value}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    }
}
