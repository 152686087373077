import {LandingSection} from "../sections/landing.section";
import {AboutMeSection} from "../sections/about-me.section";
import {ServiceSection} from "../sections/service.section";
import {ContactSection} from "../sections/contact.section";
import NavComponent from "../components/Nav.component";
import React from "react";
import FooterSection from "../sections/Footer.section";
import {DataService} from "../shared/data.service";

export class HomePage extends React.Component<any, {isInitialized: boolean}> {
    render() {
        return <div className="App">
            <LandingSection/>
            <AboutMeSection/>
            <ServiceSection/>
            <ContactSection/>
            <FooterSection />
        </div>
    }
}
