import React from "react";
import {LINK_TO_IMPRINT_SECTION} from "../shared/router.constants";

export class ImprintPage extends React.Component {
    render() {
        return <section id={LINK_TO_IMPRINT_SECTION} className="bg-light-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Impressum</h1><br/>
                    </div>
                    <div className="col-12">
                        <p style={{whiteSpace: 'pre-line'}}>
                            Malermeister Lorenz Widauer<br/>
                            Walchstraße 4 /Top 3<br/>
                            6322 Kirchbichl<br/><br/>

                            +43 680 205 98 09<br/>
                            info@malerlw.at<br/><br/>

                            www.malerlw.at<br/>
                            UID: ATU75933849<br/><br/>

                            Gewerbeordnung: www.ris.bka.gv.at<br/>
                            Aufsichtsbehörde/Gewerbebehörde: Bezirkshauptmannschaft Kufstein<br/>
                            Meisterbetrieb<br/>
                            Meisterprüfung abgelegt in Österreich<br/>
                            Lorenz Widauer in 6322 Kirchbichl | WKO Firmen A-Z<br/><br/>

                            Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der
                            EU zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an die oben
                            angegebene E-Mail-Adresse richten.<br/><br/>
                            <a href="/agb.pdf" download>AGB</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    }
}
