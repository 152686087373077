import logoBlack from '../shared/images/logo.svg';
import logoGreen from '../shared/images/logo.png';

import React from "react";
import {handleColorFunction} from "../shared/functions/handle-color.function";
import {withRouterWrapper} from "../shared/functions/with-router-wrapper.function";
import {Link} from "react-router-dom";
import {scrollToTop} from "../shared/functions/scroll-to-top.function";

export type Color = "bg-green" | "bg-black" | "bg-light-gray";

export default class LogoComponent extends React.Component<any, { activeColor: Color }> {
    private readonly _colorGreen: Color = "bg-green";
    private readonly _colorBlack: Color = "bg-black";
    private readonly _colorGray: Color = "bg-light-gray";

    private _prevColor: Color = "bg-light-gray";
    private _logos: { [key in Color]: string } = {
        "bg-green": logoBlack,
        "bg-black": logoGreen,
        "bg-light-gray": logoGreen
    };

    state = {
        activeColor: this._colorGray
    }

    constructor(props: any) {
        super(props);
        this._handleScroll = this._handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }

    private _handleScroll() {
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        const color = handleColorFunction();
        if(this.state.activeColor !== color) this.setColor(color);
    }

    public setColor(color: Color) {
        this._prevColor = this.state.activeColor;
        this.setState({activeColor: color})
    }

    public resetColorToLast() {
        this.setColor(this._prevColor);
    }

    render() {
        return (
            <Link to="/" onClick={scrollToTop}>
                {
                    this.state.activeColor == this._colorGray
                        ? <img className="logo" src={logoGreen}
                               alt='LogoComponent von Malermeister Lorenz Widauer.'/>
                        : <img className="logo" src={logoBlack}
                                   alt='LogoComponent von Malermeister Lorenz Widauer.'/>
                }
            </Link>
        )
    }
}

