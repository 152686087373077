import data from "./mock/data.json";

type Data = typeof data;


export class DataService {
    private static _instance: DataService;
    private _data!: Data;

    private constructor() {
    }

    static get instance() {
        if(!this._instance) {
            this._instance = new DataService();
        }
        return this._instance;
    }

    async init() {
        this._data = data;
    }

    get services () {
        return this._data.service;
    }

    get aboutMe() {
        return this._data.aboutMe;
    }

    get contact() {
        return this._data.contact;
    }

    get all() {
        return this._data;
    }

    get landing() {
        return this._data.landing;
    }
}
