import {Link} from "react-router-dom";
import React from "react";
import {scrollToTop} from "../shared/functions/scroll-to-top.function";
import {DataService} from "../shared/data.service";

export default class FooterSection extends React.Component {
    get _data() {
        return DataService.instance.all
    }
    render() {
        return (
            <footer className="bg-green ">
                <div className="container py-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <Link to="/impressum">{this._data.imprint.header}</Link>
                        <a className="up" onClick={scrollToTop}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                                 fill="#000000">
                                <g>
                                    <path d="M0,0h24v24H0V0z" fill="none"/>
                                </g>
                                <g>
                                    <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/>
                                </g>
                            </svg>
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
}
