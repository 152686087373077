import React, {Ref, RefObject} from "react";
import {HamburgerComponent} from "./Hamburger.component";
import {addCssClassToRef} from "../shared/functions/add-css-class-to-ref.function";
import {removeCssClassFromRef} from "../shared/functions/remove-css-class-from-ref.function";
import {ListComponent} from "./List.component";
import {
    LINK_TO_ABOUT_ME_SECTION,
    LINK_TO_CONTACT_SECTION,
    LINK_TO_IMPRINT_SECTION,
    LINK_TO_LANDING_SECTION,
    LINK_TO_SERVICE_SECTION
} from "../shared/router.constants";
import {Link, NavigateFunction} from "react-router-dom";
import {withRouterWrapper} from "../shared/functions/with-router-wrapper.function";
import LogoComponent from "./Logo.component";
import {DataService} from "../shared/data.service";
import {ScrollService} from "../shared/scroll.service";

class NavComponent extends React.Component<{ navigate: NavigateFunction, location: Location }, { activePage: string }> {
    private _lastScrollTop = 0;

    private _navContainerRef!: RefObject<HTMLDivElement>;
    private _navContentRef!: RefObject<HTMLDivElement>;
    private _hamburger!: RefObject<HamburgerComponent>;
    private _logoRef!: RefObject<LogoComponent>;

    private _contentIsVisible = false;
    private readonly isVisibleCssClass = "is-visible";

    state = {
        activePage: LINK_TO_LANDING_SECTION
    }

    constructor(props: any) {
        super(props);
        this._initReferences();
    }

    componentDidMount() {
        window.addEventListener('scroll', this._handleScroll);
        this._handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._handleScroll);
    }

    private _handleHamburgerClick() {
        if (!this._contentIsVisible) {
            ScrollService.instance.allowScroll();
            addCssClassToRef(this._navContentRef, this.isVisibleCssClass);
            addCssClassToRef(this._navContainerRef, this.isVisibleCssClass);
            const hash = this.props.location.hash.replace("#", "");
            this.setState({activePage: hash})
            this._logoRef.current?.setColor('bg-black');
        } else {
            ScrollService.instance.denyScroll();
            removeCssClassFromRef(this._navContentRef, this.isVisibleCssClass);
            removeCssClassFromRef(this._navContainerRef, this.isVisibleCssClass);
            this._logoRef.current?.resetColorToLast();
        }
        this._contentIsVisible = !this._contentIsVisible;
    }

    private _handleScroll() {
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        this._toggleNav(scrollTop);
        this._updateHashRouting(scrollTop);
    }

    private _toggleNav(scrollTop: number) {
        if (this._navContainerRef?.current) {
            if (scrollTop > this._lastScrollTop) {
                this._navContainerRef.current.style.top = '-200px';
            } else {
                this._navContainerRef.current.style.top = '0';
            }
            this._lastScrollTop = scrollTop;
        }
    }

    private _updateHashRouting(scrollTop: number) {
        Array.from(document.getElementsByTagName("section")).forEach(el => {
            const {top, bottom} = el.getBoundingClientRect();
            if (top < scrollTop && (top + el.offsetHeight) > scrollTop) {
                const {hash} = this.props.location;
                if (hash.replace("#", "") !== el.id) {
                    this.props.navigate("#" + el.id);
                }
            }
        })
    }


    private _initReferences() {
        this._navContainerRef = React.createRef();
        this._handleScroll = this._handleScroll.bind(this);

        this._navContentRef = React.createRef();
        this._handleHamburgerClick = this._handleHamburgerClick.bind(this);

        this._hamburger = React.createRef();
        this._logoRef = React.createRef();
    }

    private _handleRouteClick(id: string) {
        this._navigateToPage(id);
        this._closeOverlay();

        if (id !== LINK_TO_IMPRINT_SECTION) {
            setTimeout(() => this._scrollToSection(id))
        }
    }

    private _navigateToPage(id: string) {
        if (id !== LINK_TO_IMPRINT_SECTION) {
            this.props.navigate('/#' + id)
        } else {
            this.props.navigate('/impressum')
        }
    }

    private _scrollToSection(id: string) {
        const el = document.getElementById(id);
        if (el) {
            el.scrollIntoView({behavior: "smooth"})
            this.setState({activePage: id});
        }
    }

    private _closeOverlay() {
        this._handleHamburgerClick();
        this._hamburger.current?.resetHamburger();
    }

    private get _data() {
        return DataService.instance.all
    }

    render() {
        return (
            <div>
                <div className="nav" ref={this._navContainerRef}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="logo-container">
                                <LogoComponent ref={this._logoRef}></LogoComponent>
                            </div>
                        </div>
                        <div>
                            <HamburgerComponent ref={this._hamburger}
                                                onClick={this._handleHamburgerClick}>
                            </HamburgerComponent>
                        </div>
                    </div>
                </div>
                <div className="nav-content bg-green color-light-gray" ref={this._navContentRef}>
                    <div className="nav-content-inner d-flex align-items-center justify-content-center">
                        <ul>
                            <li onClick={(e) => this._handleRouteClick(LINK_TO_ABOUT_ME_SECTION)} className="my-1">
                                <h1 className={this.state.activePage == LINK_TO_ABOUT_ME_SECTION ? 'is-active' : ''}>
                                    {this._data.aboutMe.header}
                                </h1>
                            </li>
                            <li onClick={(e) => this._handleRouteClick(LINK_TO_SERVICE_SECTION)} className="my-1">
                                <h1 className={this.state.activePage == LINK_TO_SERVICE_SECTION ? 'is-active' : ''}>
                                    {this._data.service.header}
                                </h1>
                            </li>
                            <li onClick={(e) => this._handleRouteClick(LINK_TO_CONTACT_SECTION)} className="my-1">
                                <h1 className={this.state.activePage == LINK_TO_CONTACT_SECTION ? 'is-active' : ''}>
                                    {this._data.contact.header}
                                </h1>
                            </li>
                            <li onClick={(e) => this._handleRouteClick(LINK_TO_IMPRINT_SECTION)} className="my-1">
                                <h1 className={this.state.activePage == LINK_TO_IMPRINT_SECTION ? 'is-active' : ''}>
                                    {this._data.imprint.header}
                                </h1>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouterWrapper(NavComponent);
