import React from "react";
import {List, ListComponent} from "../components/List.component";
import {LINK_TO_LANDING_SECTION} from "../shared/router.constants";
// @ts-ignore
import TypeAnimation from 'react-type-animation';
import {DataService} from "../shared/data.service";

export class LandingSection extends React.Component<any, { text: string }> {
    state = {
        text: ""
    }

    createTextAnimationSequence(value: any) {
        if (Array.isArray(value)) {
            return value.reduce((p, c) => [...p, Math.floor(Math.random() * 1000) + 500, c], [])
        }
        return []
    }

    private get _landingData() {
        return DataService.instance.landing;
    }

    private get _data() {
        return DataService.instance.all;
    }

    render() {
        return (
            <section id={LINK_TO_LANDING_SECTION} className="d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-4">
                            <h1 className="display-1">{this._data.job}<br/><span
                                className="color-green">{this._data.name}</span></h1>
                        </div>
                        <div className="col-12">
                            <ul className="list-group list-group-flush list-lines">
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <p>{this._landingData.infos[0].label}</p>
                                    <span className="color-green bold">
                                        <TypeAnimation
                                            cursor={false}
                                            sequence={this.createTextAnimationSequence(this._landingData.infos[0].value)}
                                            wrapper="p"
                                            repeat={Infinity}
                                        />
                                    </span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <p>{this._landingData.infos[1].label}</p>
                                    <p>{this._landingData.infos[1].value}</p>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                    <p>{this._landingData.infos[2].label}</p>
                                    <p>{this._landingData.infos[2].value}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
