import React from 'react';
import {LandingSection} from "./sections/landing.section";
import {ServiceSection} from "./sections/service.section";
import {ContactSection} from "./sections/contact.section";
import {AboutMeSection} from "./sections/about-me.section";
import {ImprintPage} from "./pages/imprint.page";
import {HomePage} from "./pages/home.page";
import {DataService} from "./shared/data.service";

function App() {
    return (
       <HomePage></HomePage>
    );
}

export default App;
